export const navs = [
  // {
  //   title: 'Home',
  //   url: '/',
  //   isDropdown: false,
  // },
  {
    id: 'category',
    title: 'Categories',
    url: '/categories',
    isDropdown: true,
  },
  {
    id: 'material',
    title: 'Materials',
    url: '/materials',
    isDropdown: true,
  },
  {
    id: 'QUALITY CONTROL',
    title: 'QUALITY CONTROL',
    url: '/quality-control',
    isDropdown: false,
  },
  // {
  //   id: 'ITEMS FOR TRADING',
  //   title: 'ITEMS FOR TRADING',
  //   url: '/items-for-trading',
  //   isDropdown: false,
  // },
  {
    title: 'How we make',
    url: '/how-we-make',
    isDropdown: false,
  },
  {
    title: 'About Us',
    url: '/about-us',
    isDropdown: false,
  },
  {
    title: 'Contact',
    url: '/contact',
    isDropdown: false,
  },
  {
    title: 'FAQs',
    url: '/faqs',
    isDropdown: false,
  },
];

import { useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

// Stylesheets 👇
import './assets/styles/app.css';
import 'react-loading-skeleton/dist/skeleton.css';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';

import 'overlayscrollbars/overlayscrollbars.css';

// Layouts
import Layout from './layouts/_Layout';

// Views
import Home from './views/Home';
import NotFound from './views/NotFound';
import Category from './views/Category';
import SearchCategory from './views/SearchCategory';
import Material from './views/Material';
import SearchMaterial from './views/SearchMaterial';
// import Timeline from './views/Timeline';
import ProductDetail from './views/ProductDetail';
import OrderMobile from './views/OrderMobile';
import About from './views/About';
import Contact from './views/Contact';
import Shop from './views/Shop';
import HowWeMake from './views/HowWeMake';
import FAQs from './views/FAQs';
import QualityControl from './views/QualityControl';

function App() {
  useEffect(() => {
    disableInspect();
  }, []);

  const disableInspect = () => {
    Array.from(document.getElementsByTagName('img')).forEach((item) => {
      item.addEventListener('dragstart', (e) => {
        e.preventDefault();
      });
    });

    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });

    document.addEventListener('keydown', (e) => {
      // "F12" key
      if (e.keyCode === 123) {
        e.preventDefault();
      }
    });
  };

  useEffect(() => {
    const handleScrollPosition = () => {
      const scrollPosition = Number(sessionStorage.getItem('scrollPosition'));
      if (scrollPosition) {
        setTimeout(() => {
          window.scrollTo(0, scrollPosition);
          sessionStorage.removeItem('scrollPosition');
        }, 800);
      }
    };

    window.onpopstate = handleScrollPosition;
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path='/' index element={<Home />} />
          {/* <Route path='/timeline' element={<Timeline />} /> */}
          <Route
            path='/categories'
            element={<Navigate to='/categories/page/1' replace />}
          />
          <Route path='/categories/page/:page' element={<Category />} />
          {/* <Route path='/categories/:path' element={<SearchCategory />} /> */}
          <Route
            path='/categories/:path/page/:page'
            element={<SearchCategory />}
          />
          <Route
            path='/materials'
            element={<Navigate to='/materials/page/1' replace />}
          />
          <Route path='/materials/page/:page' element={<Material />} />
          {/* <Route path='/materials/:path' element={<Navigate to='/materials/:path/page/1' replace />} /> */}
          <Route
            path='/materials/:path/page/:page'
            element={<SearchMaterial />}
          />
          <Route path='/products/page/:page' element={<Shop />} />

          {/* <Route path='/items-for-trading' element={<ItemsTrading />} /> */}
          <Route
            path='/products'
            element={<Navigate to='/products/page/1' replace />}
          />
          <Route path='/product/:path' element={<ProductDetail />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about-us' element={<About />} />
          <Route path='/faqs' element={<FAQs />} />
          <Route path='/how-we-make' element={<HowWeMake />} />
          <Route path='/quality-control' element={<QualityControl />} />
          <Route path='/order/:path' element={<OrderMobile />} />
          <Route path='/not-found' element={<NotFound />} />
        </Route>
        <Route path='*' element={<Navigate to='/not-found' replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
